
import { plural } from '@@/common/assets/js/utils/text-utils';

import ContentBlockWrapperWithHeader from 'portal/components/common/content-block/ContentBlockWrapperWithHeader.vue';
import VacancyJobCard from './VacancyJobCard.vue';
import VacancyStatsCard from './VacancyStatsCard.vue';

const TEXT = {
    TITLE: 'Работа',
    BUTTON_TEXT: 'Перейти в раздел',
    STATS_CARD_TITLE: 'Теперь команды<br>собирают здесь!',
    STATS_CARD_LABEL: 'Найди команду мечты',
    VACANCIES_TITLE: 'Новые вакансии',
    STATS_CARD_BTN_TEXT_VACANCIES: 'Вакансии',
    STATS_CARD_BTN_TEXT_RESUMES: 'Резюме',
    STATS_CARD_BTN_TEXT_COMPANIES: 'Каталог работодателей',
    STATS_CARD_VACANCIES_DESCRIPTION: ['активная вакансия', 'активные вакансии', 'активных вакансий'],
    STATS_CARD_RESUMES_DESCRIPTION: ['резюме соискателя', 'резюме соискателей', 'резюме соискателей'],
    STATS_CARD_COMPANIES_DESCRIPTION: ['работодатель в поиске сотрудников', 'работодателя в поиске сотрудников', 'работодателей в поиске сотрудников'],
};

export default {
    name: 'VacancyBlock',

    components: {
        VacancyStatsCard,
        VacancyJobCard,
        ContentBlockWrapperWithHeader,
    },

    props: {
        vacancies: {
            type: Array,
            default: () => [],
        },

        vacanciesNumber: {
            type: Number,
            default: 0,
        },

        resumesNumber: {
            type: Number,
            default: 0,
        },

        companiesNumber: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            TEXT: TEXT,
        };
    },

    computed: {
        vacanciesDescription() {
            return `${plural(this.vacanciesNumber, this.TEXT.STATS_CARD_VACANCIES_DESCRIPTION)}`;
        },

        resumesDescription() {
            return `${plural(this.resumesNumber, this.TEXT.STATS_CARD_RESUMES_DESCRIPTION)}`;
        },

        companiesDescription() {
            return `${plural(this.companiesNumber, this.TEXT.STATS_CARD_COMPANIES_DESCRIPTION)}`;
        },

        vacanciesTitle() {
            return this.vacanciesNumber.toString();
        },

        resumesTitle() {
            return this.resumesNumber.toString();
        },

        companiesTitle() {
            return this.companiesNumber.toString();
        },
    },

    methods: {
        getLinkToVacancy(vacancy) {
            return `/job/vacancy/${vacancy.id}`;
        },
    },
};
