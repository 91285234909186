// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VacancyJobCard_d6tFH{display:flex;position:relative;width:100%}.VacancyJobCard_d6tFH:hover .position_t\\+Per{color:#855aff}@media(max-width:767.98px){.VacancyJobCard_d6tFH{margin-bottom:1.2rem}}.position_t\\+Per{display:-webkit-box;font-size:1.4rem;font-weight:500;letter-spacing:-.056rem;line-height:1.68rem;margin-top:.8rem;overflow:hidden;transition:.3s ease;-webkit-line-clamp:2;-webkit-box-orient:vertical}@media(max-width:1279.98px){.position_t\\+Per{font-size:1.6rem;font-weight:500;letter-spacing:-.064rem;line-height:1.92rem}}.salary_dvMw5{font-size:1.6rem;font-weight:500;letter-spacing:-.064rem;line-height:1.92rem;margin-top:.4rem}@media(max-width:1279.98px){.salary_dvMw5{font-size:1.8rem;font-weight:500;letter-spacing:-.072rem;line-height:2.16rem}}.company_T7j3s{background-color:#f1f1f4;border-radius:.6rem;font-size:1.2rem;font-weight:500;letter-spacing:-.048rem;line-height:1.44rem;margin-top:.8rem;padding:.4rem .8rem .4rem .2rem;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.link_Shp97{bottom:0;left:0;position:absolute;right:0;top:0;z-index:2}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"VacancyJobCard": "VacancyJobCard_d6tFH",
	"position": "position_t+Per",
	"salary": "salary_dvMw5",
	"company": "company_T7j3s",
	"link": "link_Shp97"
};
module.exports = ___CSS_LOADER_EXPORT___;
