// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VacancyStatsCard_GZDNY{align-items:flex-start;align-self:stretch;background-color:#fff;border-radius:2rem;display:flex;flex-direction:column;justify-content:flex-start;padding:2.4rem;transition:opacity .3s ease;width:100%}@media(max-width:1279.98px){.VacancyStatsCard_GZDNY{padding:1.6rem}}.VacancyStatsCard_GZDNY._small_B5ipI .bottom_YNI9u{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}@media(max-width:1279.98px){.VacancyStatsCard_GZDNY._small_B5ipI .buttonLabel_sQJ3D{display:none}}.VacancyStatsCard_GZDNY._black_uemE1{background-color:#17181c}.VacancyStatsCard_GZDNY._black_uemE1 .title_Vgy40{color:#fff}.VacancyStatsCard_GZDNY._black_uemE1 .description_8g6iz{color:#fff;opacity:.6}.VacancyStatsCard_GZDNY._primary_gEzGr{background-color:#855aff}.VacancyStatsCard_GZDNY._primary_gEzGr .title_Vgy40{color:#fff;font-size:2.8rem;font-weight:500;letter-spacing:-.112rem;line-height:3.08rem}@media(max-width:1279.98px){.VacancyStatsCard_GZDNY._primary_gEzGr .title_Vgy40{font-size:2.4rem;font-weight:500;letter-spacing:-.096rem;line-height:2.64em;line-height:2.64rem}}.VacancyStatsCard_GZDNY._primary_gEzGr .description_8g6iz{color:#fff;opacity:.6}.VacancyStatsCard_GZDNY._clickable_c3GHR:hover{opacity:.9}.title_Vgy40{font-size:4rem;font-weight:500;letter-spacing:-.16rem;line-height:4rem}@media(max-width:1279.98px){.title_Vgy40{font-size:2.8rem;font-weight:500;letter-spacing:-.112rem;line-height:3.08rem}}.description_8g6iz{color:#73788c;font-size:1.6rem;font-weight:400;letter-spacing:-.064rem;line-height:1.92rem;margin-top:.8rem}@media(max-width:1279.98px){.description_8g6iz{font-size:1.4rem;font-weight:400;letter-spacing:-.056rem;line-height:1.68rem;margin-top:.2rem}}.bottom_YNI9u{margin-top:auto}@media(max-width:1279.98px){.bottom_YNI9u{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"VacancyStatsCard": "VacancyStatsCard_GZDNY",
	"_small": "_small_B5ipI",
	"bottom": "bottom_YNI9u",
	"buttonLabel": "buttonLabel_sQJ3D",
	"_black": "_black_uemE1",
	"title": "title_Vgy40",
	"description": "description_8g6iz",
	"_primary": "_primary_gEzGr",
	"_clickable": "_clickable_c3GHR"
};
module.exports = ___CSS_LOADER_EXPORT___;
