
import VButtonNew from '../../ui/button/VButtonNew.vue';

export default {
    name: 'VacancyStatsCard',

    components: {
        VButtonNew,
    },

    props: {
        size: {
            type: String,
            default: 'default',
            validator: value => [
                'default',
                'small',
            ].includes(value),
        },

        bgColor: {
            type: String,
            default: 'white',
            validator: value => [
                'white',
                'black',
                'primary',
            ].includes(value),
        },

        title: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },

        btnColor: {
            type: String,
            default: 'base-100-500',
        },

        btnText: {
            type: String,
            default: '',
        },

        navLinkTo: {
            type: String,
            default: '',
        },

        clickable: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style._white]: this.bgColor === 'white',
                [this.$style._black]: this.bgColor === 'black',
                [this.$style._primary]: this.bgColor === 'primary',
                [this.$style._clickable]: this.clickable,
            };
        },

        getComponent() {
            if (this.clickable || this.isTablet && this.size === 'small') {
                return 'n-link';
            }

            return 'div';
        },

        isTablet() {
            return this.$deviceIs.mobile || this.$deviceIs.tablet;
        },

        isSquareBtn() {
            return this.size === 'small' && this.isTablet;
        },
    },
};
